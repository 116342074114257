.owl-next {
  position: absolute;
  top     : 36%;
  right   : -7%;
}

.owl-prev {
  position: absolute;
  top     : 36%;
  left    : -7%;
}

/**
 * Owl Carousel v2.2.1
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
/*
 *  Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top                 : 10px;
  text-align                 : center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav [class*='owl-'] {
  color        : #178097;
  font-size    : 50px;
  margin       : 0px;
  padding      : 0px 7px;
  background   : none;
  display      : inline-block;
  cursor       : pointer;
  border-radius: 3px;
}

.owl-theme .owl-nav [class*='owl-']:hover {
  background     : none;
  color          : rgba(70, 156, 232, 0.5);
  text-decoration: none;
}

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor : default;
}

.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 10px;
}

.owl-theme .owl-dots {
  text-align                 : center;
  -webkit-tap-highlight-color: transparent;
  margin-top                 : 30px;
}

.owl-theme .owl-dots .owl-dot {
  display : inline-block;
  zoom    : 1;
  display: inline;
}

.owl-theme .owl-dots .owl-dot span {
  width                      : 12px;
  height                     : 12px;
  margin                     : 5px 7px;
  background                 : #4bb2e9;
  display                    : block;
  -webkit-backface-visibility: visible;
  transition                 : opacity 200ms ease;
  border-radius              : 30px;
  border                     : 2px solid #4bb2e9;

}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #fff;
  border    : 2px solid #594cca;
  width     : 12px;
  height    : 12px;
}
